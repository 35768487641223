import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref, reactive, computed } from 'vue'
import { useTimestamp } from '@vueuse/core'
import {
  getMe,
  resetSystemToInitialState,
  getMiniStepperStatus,
  setMiniStepperStatusLayoutsVisited,
  getStepperStatus,
} from '@/api/auth'
import {
  getFields,
  getFieldsPerMultiMap,
  getFieldOperators,
} from '@/api/metadata'
import { getRoles } from '@/api/roles'
import { useConnections } from './connections'

export const useSession = defineStore('session', () => {
  const connectionsStore = useConnections()

  const isStartingPageLoading = ref(false)
  const reindexingInterval = 5 * 60000 // 5 min

  const dedupeAgentId = ref(localStorage.getItem('dedupeAgentId'))
  const searchAgentId = ref(localStorage.getItem('searchAgentId'))
  const findFixAgentId = ref(localStorage.getItem('findFixAgentId'))
  const importUrl = ref(null)

  const stepper = ref(JSON.parse(localStorage.getItem('stepper')))

  const enableXOBreakup = ref(false)

  const user = ref(JSON.parse(localStorage.getItem('user')))

  const herokuToken = ref(localStorage.getItem('herokuToken'))

  const systemStatus = ref(JSON.parse(localStorage.getItem('systemStatus')))

  const fieldsPerObject = ref(
    JSON.parse(localStorage.getItem('fieldsPerObject')),
  )
  const fieldsPerMultiMap = ref(
    JSON.parse(localStorage.getItem('fieldsPerMultiMap')),
  )
  const operators = ref(JSON.parse(localStorage.getItem('operators')))

  const contextMessage = ref(null)
  const timestamp = useTimestamp()
  const roles = ref(null)
  const jwt = ref(null)
  const isSessionExpired = ref(false)
  const lastRequestTimestamp = ref(null)
  const isUserError = ref(false)
  const isLoadingSystemStatus = ref(false)
  const isSystemStatusError = ref(false)
  const isLoadingMetadata = ref(false)
  const requesting = reactive({
    user: false,
    resetSystem: false,
    roles: false,
    stepper: false,
  })

  const isUserLoggedIn = computed(() => {
    return jwt.value || herokuToken.value
  })

  const isUserInactive = computed(() => {
    return (
      isUserLoggedIn.value &&
      timestamp.value &&
      lastRequestTimestamp.value &&
      timestamp.value > lastRequestTimestamp.value
    )
  })

  function track(event) {
    if (contextMessage.value) {
      window.plausible(event, {
        props: { u: contextMessage.value.userId },
      })
    }
  }

  function setContextMessage(message) {
    contextMessage.value = message
    importUrl.value =
      window.location.origin +
      '/ci/action/import/entity/ui/?session=' +
      btoa(message.userName + '_session_' + message.sessionId)
  }

  async function getUser() {
    requesting.user = true
    isUserError.value = false

    try {
      const sessionData = await getMe()

      connectionsStore.setConnectionsList(sessionData.connections)

      enableXOBreakup.value = sessionData.enableXOBreakup

      const userData = {
        id: sessionData.id,
        isSearchBeforeCreateEnabled: sessionData.isSearchBeforeCreateEnabled,
        isImportEnabled: sessionData.isImportEnabled,
      }

      user.value = userData
      localStorage.setItem('user', JSON.stringify(userData))

      systemStatus.value = sessionData.systemStatus
      localStorage.setItem(
        'systemStatus',
        JSON.stringify(sessionData.systemStatus),
      )

      if (systemStatus.value.isCrawlerReindexing) {
        setTimeout(
          () => getSystemStatus({ withLoader: false }),
          reindexingInterval,
        )
      }

      stepper.value = sessionData.stepperStatus
      localStorage.setItem('stepper', JSON.stringify(sessionData.stepperStatus))

      dedupeAgentId.value = sessionData.agents.find(
        rule => rule.type === 'dedupe',
      )?.id
      searchAgentId.value = sessionData.agents.find(
        rule => rule.type === 'search',
      )?.id
      findFixAgentId.value = sessionData.agents.find(
        rule => rule.type === 'findfix',
      )?.id

      localStorage.setItem('dedupeAgentId', dedupeAgentId.value)
      localStorage.setItem('searchAgentId', searchAgentId.value)
      localStorage.setItem('findFixAgentId', findFixAgentId.value)
    } catch (error) {
      isUserError.value = true
      throw error
    } finally {
      requesting.user = false
    }
  }

  async function getStepper({ fullReload = true } = {}) {
    try {
      requesting.stepper = true
      const data = await getStepperStatus()

      if (fullReload) {
        stepper.value = data
      } else {
        stepper.value.isSalesforcePackageInstalled =
          data.isSalesforcePackageInstalled

        if (!stepper.value.otpKey) {
          stepper.value.otpKey = data.otpKey
        }
      }

      return stepper.value
    } finally {
      requesting.stepper = false
    }
  }

  const packageInstallUrl = computed(() => {
    if (connectionsStore.salesforceConnection.url.endsWith('/')) {
      return (
        connectionsStore.salesforceConnection.url.slice(0, -1) +
        stepper.value.packageInstallUrl
      )
    } else {
      return (
        connectionsStore.salesforceConnection.url +
        stepper.value.packageInstallUrl
      )
    }
  })

  async function getRolesList() {
    requesting.roles = true
    roles.value = await getRoles()
    requesting.roles = false
  }

  function setJWT(value) {
    jwt.value = value
  }

  function setSessionExpired() {
    isSessionExpired.value = true
  }

  async function setHerokuToken(value) {
    herokuToken.value = value
    localStorage.setItem('herokuToken', value)
  }

  function setLastRequestTimestamp() {
    const currentTime = new Date()
    currentTime.setMinutes(currentTime.getMinutes() + 60)
    lastRequestTimestamp.value = currentTime.getTime()
  }

  function signOut() {
    localStorage.removeItem('env')
    localStorage.removeItem('jwt')
    localStorage.removeItem('herokuToken')
    localStorage.removeItem('authJson')

    jwt.value = null
    herokuToken.value = null
  }

  async function resetSystem() {
    try {
      requesting.resetSystem = true
      await resetSystemToInitialState()
      stepper.value = null
    } finally {
      requesting.resetSystem = false
    }
  }

  async function getSystemStatus({ withLoader = true } = {}) {
    if (withLoader) {
      isLoadingSystemStatus.value = true
    }

    isSystemStatusError.value = false

    try {
      const miniStepper = await getMiniStepperStatus()
      systemStatus.value = miniStepper
      localStorage.setItem('systemStatus', JSON.stringify(miniStepper))

      if (systemStatus.value.isCrawlerReindexing) {
        setTimeout(
          () => getSystemStatus({ withLoader: false }),
          reindexingInterval,
        )
      }
    } catch (error) {
      isSystemStatusError.value = true
      throw error
    } finally {
      isLoadingSystemStatus.value = false
    }
  }

  async function setSystemStatusLayoutsVisited() {
    await setMiniStepperStatusLayoutsVisited()
    systemStatus.value = {
      ...systemStatus.value,
      layoutsVisited: true,
    }
  }

  async function getSystemMetadata() {
    isLoadingMetadata.value = true

    Promise.all([
      getFieldsPerObjectMetadata(),
      getFieldsPerMultimapMetadata(),
      getFieldOperatorsMetadata(),
    ]).finally(() => {
      isLoadingMetadata.value = false
    })
  }

  const isFieldOperatorsError = ref(false)
  const isFieldsPerObjectError = ref(false)
  const isFieldsPerMultimapError = ref(false)
  const isLoadingFieldsPerObject = ref(false)

  async function getFieldOperatorsMetadata() {
    try {
      isFieldOperatorsError.value = false
      const list = await getFieldOperators()
      operators.value = list
      localStorage.setItem('operators', JSON.stringify(list))
    } catch (error) {
      isFieldOperatorsError.value = true
      throw error
    }
  }

  async function getFieldsPerMultimapMetadata() {
    try {
      isFieldsPerMultimapError.value = false
      const list = await getFieldsPerMultiMap()
      fieldsPerMultiMap.value = list
      localStorage.setItem('fieldsPerMultiMap', JSON.stringify(list))
    } catch (error) {
      isFieldsPerMultimapError.value = true
      throw error
    }
  }

  async function getFieldsPerObjectMetadata({ flushCache, object } = {}) {
    try {
      isLoadingFieldsPerObject.value = true
      isFieldsPerObjectError.value = false
      const list = await getFields({ flushCache, object })
      if (object) {
        fieldsPerObject.value = {
          ...fieldsPerObject.value,
          ...list,
        }
      } else {
        fieldsPerObject.value = list
      }

      localStorage.setItem('fieldsPerObject', JSON.stringify(list))
    } catch (error) {
      isFieldsPerObjectError.value = true
      throw error
    } finally {
      isLoadingFieldsPerObject.value = false
    }
  }

  return {
    importUrl,

    getSystemMetadata,

    getFieldOperatorsMetadata,
    getFieldsPerMultimapMetadata,
    getFieldsPerObjectMetadata,

    isFieldOperatorsError,
    isFieldsPerObjectError,
    isFieldsPerMultimapError,
    isLoadingFieldsPerObject,

    isStartingPageLoading,
    isLoadingMetadata,

    fieldsPerObject,
    fieldsPerMultiMap,
    operators,

    enableXOBreakup,

    dedupeAgentId,
    searchAgentId,
    findFixAgentId,

    getStepper,
    packageInstallUrl,
    stepper,

    contextMessage,
    requesting,
    isUserLoggedIn,
    isUserInactive,
    isSessionExpired,
    herokuToken,
    jwt,
    systemStatus,
    isLoadingSystemStatus,
    isSystemStatusError,
    user,
    isUserError,
    roles,
    setContextMessage,
    getUser,
    getRolesList,
    setJWT,
    setHerokuToken,
    setSessionExpired,
    setLastRequestTimestamp,
    signOut,
    resetSystem,
    track,
    getSystemStatus,
    setSystemStatusLayoutsVisited,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSession, import.meta.hot))
}
